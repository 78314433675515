import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ThemeLayout from '../../layouts/layout';
import SignUp from '../../components/organisms/Auth/SignUp';
import { isGlobalSite } from '../../helpers/data';
import SEO from '../../components/seo';

const SignUpPage = ({ data: { siteConfig } }) => {
  if (isGlobalSite) {
    return (
      <SEO meta={[
        { 'http-equiv': 'refresh', content: '0;url=/' },
      ]}
      />
    );
  }
  return (
    <ThemeLayout>
      <SignUp GDPRCountry={siteConfig.GDPRCountry} isModal={false} />
    </ThemeLayout>
  );
};

SignUpPage.propTypes = {
  data: PropTypes.shape().isRequired,
};
SignUpPage.defaultProps = {};

export default SignUpPage;

export const query = graphql`
  query SignUpPageQuery {
    siteConfig {
      GDPRCountry
    }
  }
`;
